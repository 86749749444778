import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"
import Title from "../components/Title"

const Podcast = ({ data }) => {
  const image = getImage(data.hero)
  return (
    <Layout>
      <Seo
        title="Podcast"
        desc="On our weekly podcast, we discuss various topics related to the course and learning Vietnamese."
      />
      <div className="blog-template">
        <Title title="Vietnamese Guide Podcast" />
        <div className="half hero" style={{ background: "none" }}>
          <p>
            On our weekly podcast, we discuss various topics related to the
            course. We also provide extra related vocabulary and an opportunity
            to answer your questions.
          </p>
          <p>
            Vietnamese Guide members get access to podcast transcripts,
            vocabulary helpers with pronunciation audio clips, and other bonus
            content.
          </p>
          <div className="desktop-only">
            <GatsbyImage image={image} alt="Vietnamese Guide" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Podcast

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "podcast.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }
  }
`
